import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export default function openSuccessModal() {
  const $successModal = $('.js-modal-success')
  if($successModal.length !== 0) {
    $('.modal').hide()
    $successModal.fadeIn().addClass('is-open')

    setTimeout(function() {
      $successModal.fadeOut().removeClass('is-open')
      enableBodyScroll('body')
    }, 4000)
  }

}

$(document).on('click', '.js-modal-open', function() {
  const $modal = $(this).data('modal')

  $($modal).fadeIn().addClass('is-open')
  disableBodyScroll('body')
})

$(document).on('click', '.js-modal-close', function() {
  const $modal = $(this).parents('.modal')

  $($modal).fadeOut().removeClass('is-open')
  enableBodyScroll('body')
})

$(window).on('orientationchange', function() {
  const $modalZoomOpen = $('.js-modal-zoom.is-open')

  if($modalZoomOpen && $(window).width() < 991) {
    $modalZoomOpen.hide().removeClass('is-open')
    enableBodyScroll('body')
  }
})

