import './vendor';

import './_parts/_main-slider.js';
import './_parts/_banner-slider.js';
import './_parts/_detail-slider.js';
import './_parts/_item-slider.js';
import './_parts/_about-tabs.js';
import './_parts/_modal.js';
import './_parts/_menu.js';
import './_parts/_zoom.js';
import './_parts/_validate.js';
import './_parts/_mask.js';
import './_parts/_dropfile.js';
