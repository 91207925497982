$.extend($.validator.messages, {
  required: 'Заполните поле',
  remote: 'Введите правильное значение',
  email: 'Введите корректную электронную почту',
  url: 'Введите корректный URL',
  date: 'Введите корректную дату',
  dateISO: 'Введите корректную дату в формате ISO',
  number: 'Введите число',
  digits: 'Вводите только цифры',
  letters: 'Вводите только буквы',
  creditcard: 'Введите правильный номер кредитной карты',
  equalTo: 'Пароли не совпадают',
  extension: 'Выберите файл с правильным расширением',
  maxlength: $.validator.format('Максимальная длина описания - {0} символов'),
  minlength: $.validator.format('Введите не меньше {0} символов'),
  rangelength: $.validator.format('Введите значение длиной от {0} до {1} символов'),
  range: $.validator.format('Введите число от {0} до {1}'),
  max: $.validator.format('Максимальная длина описания - {0} символов'),
  min: $.validator.format('Введите число, большее или равное {0}'),
})

$('.js-validate').each((index, form) => {
  $(form).validate({
    ignore: [':disabled'],
    errorPlacement: function(error,element) {
      return true;
    },
    highlight(element) {
      setTimeout(() => {
        $(element).parent().removeClass('is-success')
        $(element).parent().addClass('is-error')
      })
    },
    unhighlight(element) {
      setTimeout(() => {
        if ($(element).val() !== '' && $(element).val() !== '0') $(element).parent().addClass('is-success')
        $(element).parent().removeClass('is-error')
      })
    },
  })
  $('input', form).on('change', function () {
    $(this).valid()
  })
})

/*
 * Номера телефона
 */

$.validator.addMethod('phonecustom', (value) => {
  return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/)
})

$.validator.addClassRules('js-mob', {
  phonecustom: true,
})

/*
 * Валидация e-mail
 */

$.validator.addMethod('emailcustom', (value) =>
   value.match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9A-Яа-я_\-\.])+\.([A-Za-zA-Яа-я_]{2,4})$/)
)

$.validator.addClassRules('js-email', {
  emailcustom: true,
})

