import Swiper from 'swiper/bundle';

$('.js-banner-slider').each(function () {
	const $this = $(this);
	if ($this.find('.swiper-slide').length > 1) {
		const $swiperContainer = $this.find('.swiper-container')[0];
		const $swiperPagination = $this.find('.swiper-pagination')[0];
		const bannerSlider = new Swiper($swiperContainer, {
			loop: true,
			grabCursor: true,

			pagination: {
				el: $swiperPagination,
			},
		});
	}
});
