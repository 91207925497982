import Swiper from 'swiper/bundle';

$('.js-detail-slider').each(function () {
	const $this = $(this);
	if ($this.find('.swiper-slide').length > 1) {
		const $swiperContainer = $this.find('.swiper-container')[0];
		const detailSlider = new Swiper($swiperContainer, {
			loop: true,
			grabCursor: true,
			slidesPerView: 1.2,
			centeredSlides: true,
		});
	}
});
