let files

$(document).on('change', '.js-file-input', function (e) {
  const target = e.target
  const $filesBox = $('.js-files-box')
  const prevFiles = $filesBox.find('.js-file')

  if(prevFiles.length) {
    prevFiles.remove()
  }

  files = target.files
  files = Object.values(files)

  files.map((item) => (
    $filesBox.append(`
      <div class="file js-file">
        <div class="file-box">
          <div class="file-btn js-remove-file"><img src="/local/templates/boffi/images/delete.png" alt=""></div><span>${item.name}</span>
          </div>
        </div>
      </div>
    `)
    ))
})

$(document).on('click', '.js-remove-file', function(e) {
  const file = $(e.target).parents('.js-file')
  const fileIdx = file.index()
  files.splice(fileIdx, 1)
  file.remove()
})