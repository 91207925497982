import Swiper from 'swiper/bundle';

$('.js-main-slider').each(function () {
	const $this = $(this);
	if ($this.find('.swiper-slide').length > 1) {
		const $swiperContainer = $this.find('.swiper-container')[0];
		const $swiperPagination = $this.find('.swiper-pagination')[0];

		const mainSlider = new Swiper($swiperContainer, {
			loop: true,
			speed: 700,
			grabCursor: true,

			autoplay: {
				delay: 5000,
			},

			pagination: {
				el: $swiperPagination,
			},

			on: {
				slideChange() {
					const { activeIndex } = this;
					const currentSlide = $('.js-main-slider .swiper-slide').eq(
						activeIndex
					);

					if (currentSlide.hasClass('js-main-video')) {
						currentSlide.find('video')[0].load();
					}
				},
			},
		});
	}
});
