import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const $menu = $('.js-menu')

$(document).on('click', '.js-open-menu', function() {
  $('.js-menu').addClass('is-open')
  $('body').addClass('is-menu-open')
  disableBodyScroll('body')
  openSuccessModal()
})

$(document).on('click', '.js-hide-menu', function() {
  $('.js-menu').removeClass('is-open')
  $('body').removeClass('is-menu-open')
  enableBodyScroll('body')
})